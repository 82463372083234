import React, { useState } from "react";
import Application from "../../components/Popups/ApplicationReceived";

const ApplicationForm: React.FC = () => {
    const [open, setOpen] = useState(false);
    const handleOpen = () =>  setOpen(true);
    const handleClose = () => setOpen(false);

    const handleSubmit = (event: React.FormEvent) => {
        event.preventDefault(); // Prevent default form submission
        handleOpen(); // Open the modal
    };


    return (
        <>
            <div className="d-flex align-items-center justify-content-center" style={{ height: '100vh' }}>
                <div className="container mt-3 bg-white" style={{
                    borderRadius: '27px',
                    border: '1px solid #D6D6D7',
                    padding: '40px',
                    maxWidth: '600px'
                }}>
                    <h2 className="text-center mb-4" style={{
                        color: '#6EC4B8',
                        fontWeight: 800
                    }}>Join Our Team - Application Form</h2>
                    <form onSubmit={handleSubmit}>
                        {/* Full Name Field */}
                        <div className="mb-3">
                            <label htmlFor="fullName" className="form-label">Full Name</label>
                            <input type="text" className="form-control" id="fullName" placeholder="Enter your full name" />
                        </div>

                        {/* Email Field */}
                        <div className="mb-3">
                            <label htmlFor="email" className="form-label">Email</label>
                            <input type="email" className="form-control" id="email" placeholder="Enter your email" />
                        </div>

                        {/* Job Title Field */}
                        <div className="mb-3">
                            <label htmlFor="jobTitle" className="form-label">Job Title</label>
                            <input type="text" className="form-control" id="jobTitle" placeholder="Enter job title" />
                        </div>

                        {/* CV Upload Field */}
                        <div className="mb-3">
                            <label htmlFor="cvFile" className="form-label">Upload CV</label>
                            <input type="file" className="form-control" id="cvFile" />
                        </div>

                        {/* Mobile Number Field */}
                        <div className="mb-4">
                            <label htmlFor="mobileNo" className="form-label">Mobile Number</label>
                            <input type="tel" className="form-control" id="mobileNo" placeholder="Enter your mobile number" />
                        </div>

                        {/* Submit Button */}
                        <div className="d-grid float-end" style={{ width: '35%' }}>
                            <button type="submit" className="btn btn-primary user-scale" style={{
                                backgroundColor: '#6EC4B8',
                                border: '1px solid #6EC4B8'
                            }}>Submit Application</button>
                        </div>
                    </form>
                </div>
            </div>

            <Application open={open} onClose={handleClose} />
        </>
    );
}

export default ApplicationForm;
