import React from "react";

interface InterviewTipProps {
  handleNext: () => void;
  handleBack: () => void;
}

const InterviewTip: React.FC<InterviewTipProps> = ({ handleNext, handleBack }) => {
  return (
    <>
      <h1>Interview Tips</h1>
      <button onClick={handleBack}>Back</button>
      <button onClick={handleNext}>Next</button>
    </>
  );
};

export default InterviewTip;
