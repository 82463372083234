import React, { useState } from "react";
import InterviewTip from "../../components/Interview/InterviewTip";
import InterviewReminder from "../../components/Interview/InterviewRemiender";
import Feedback from "../../components/Interview/Feedback";

const InterviewProcess: React.FC = () => {
  const [step, setStep] = useState(0);

  const handleNext = () => {
    if (step < 3) setStep(step + 1);
  };

  const handleBack = () => {
    if (step > 0) setStep(step - 1);
  };

  // Function to render the current step
  const renderStepContent = (step: number) => {
    switch (step) {
      case 0:
        return (
          <>
            <h1>Interview Confirmation</h1>
            <button onClick={handleNext}>Next</button>
          </>
        );
      case 1:
        return <InterviewTip handleNext={handleNext} handleBack={handleBack} />;
      case 2:
        return <InterviewReminder handleNext={handleNext} handleBack={handleBack} />;
      case 3:
        return <Feedback handleNext={handleNext} handleBack={handleBack} />;
      default:
        return null;
    }
  };

  return <>{renderStepContent(step)}</>;
};

export default InterviewProcess;
