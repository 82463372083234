import React, { useEffect, useRef, useState } from "react";
import JobProfileFirst from "../../components/JobSpecification/JobProfileFirst";
import { Box, Grid, Typography, useMediaQuery } from "@mui/material";
import OverviewIcon from "../../assets/images/JobIcon/Overview.svg";
import GoalIcon from "../../assets/images/JobIcon/goal.svg";
import CheckIcon from "../../assets/images/JobIcon/check-bold.svg";
import SymbolsIcon from "../../assets/images/JobIcon/symbols_step.svg";
import TeamsIcon from "../../assets/images/JobIcon/team-people.svg";
import JobProfileSecond from "../../components/JobSpecification/JobProfileSecond";
import JobProfileThird from "../../components/JobSpecification/JobProfileThird";
import JobProfilefourth from "../../components/JobSpecification/JobProfilefourth";
import JobProfilefifth from "../../components/JobSpecification/JobProfilefifth";
import JobProfileSixth from "../../components/JobSpecification/JobProfileSixth";
import { useParams } from "react-router-dom";
import { getRequest } from "../../api/apiServices";
import { getJob } from "../../api/apiList";
import Loading from "../../utils/Loading"
import { gsap } from 'gsap';

const JobSpecification: React.FC = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const isMobile = useMediaQuery("(max-width:635px)");
  const [loading, setLoading] = useState(false)
  const [update, setUpdate] = useState(false)
  const leftToRight = useRef(null);
  const rightToLeft = useRef(null);

  // To Handle the data
  const [formData, setFormData] = useState({});

  // Get The Id
  const params = useParams()

  const goToNextPage = () => {
    setCurrentPage(currentPage + 1);
  };

  const goToPreviousPage = () => {
    setCurrentPage(currentPage - 1);
  };

  const handleOverviewClick = () => {
    setCurrentPage(1);
  };

  const handleResponsibilitiesClick = () => {
    setCurrentPage(2);
  };

  const handleChallengesClick = () => {
    setCurrentPage(3);
  };

  const handleTeamCultureClick = () => {
    setCurrentPage(4);
  };

  const handleInterviewprocesClick = () => {
    setCurrentPage(5);
  };
  // Get The Job Data
  const getJobData = async () => {
    const response = await getRequest(getJob(params?.id))
    return response?.data?.data
  }
  useEffect(() => {
    const fetchData = async () => {
      try {
        if (params?.id) {
          setLoading(true);
          const data = await getJobData()
          setFormData(data)
          setUpdate(true)
          setLoading(false);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    }
    fetchData()
  }, [params?.id])

  useEffect(() => {
    if (leftToRight.current && rightToLeft.current) {
      const timeLine = gsap.timeline();
      
      timeLine.fromTo(
        leftToRight.current,
        { x: -100, opacity: 0 },
        { x: 0, opacity: 1, duration: 1 }
      );
  
      timeLine.fromTo(
        rightToLeft.current,
        { x: 100, opacity: 0 },
        { x: 0, opacity: 1, duration: 1 },
        "<" // run this animation at the same time as the previous one
      );
    }
  }, [currentPage]); // Add currentPage to run animations on page change
  


  return loading ? <Loading /> : (
    <div >
      <Grid
        container
        spacing={1}
        justifyContent={isMobile ? "center" : "center"}
      >
        {currentPage !== 6 && (
          <>
            <Grid ref={rightToLeft} xs={2} maxWidth={isMobile ? "none" : "auto"}>
              <Grid mt="33px"></Grid>
              <Grid
                className="user"
                direction="column"
                display="flex"
                justifyContent="space-between"
                alignItems="center"
                bgcolor="#FFFFFF"
                borderRadius="14px"
                border="1px solid #D9D9D9"
                onClick={handleOverviewClick}
                style={{ cursor: "pointer" }}
              >
                <Grid padding="28px 90px 0px 98px">
                  <Box padding="15px" borderRadius="7px" bgcolor="#84E1CC">
                    <img src={OverviewIcon} />
                  </Box>
                </Grid>
                <Grid padding="17px 35px 26px 35px">
                  <Typography fontSize="13px" fontWeight={500} color="#273848">
                    Overview &amp;
                    <br />
                    Job features
                  </Typography>
                </Grid>
              </Grid>
              <Grid mt="26px"></Grid>
              <Grid
              className="user"
                direction="column"
                display="flex"
                justifyContent="space-between"
                alignItems="center"
                bgcolor="#FFFFFF"
                borderRadius="14px"
                border="1px solid #D9D9D9"
                onClick={handleResponsibilitiesClick}
                style={{ cursor: "pointer" }}
              >
                <Grid padding="28px 90px 0px 98px">
                  <Box padding="15px" borderRadius="7px" bgcolor="#84E1CC">
                    <img src={CheckIcon} />
                  </Box>
                </Grid>
                <Grid padding="17px 35px 26px 35px">
                  <Typography
                    fontSize="13px"
                    lineHeight="15.79px"
                    fontWeight={500}
                    color="#273848"
                  >
                    Responsibilities &amp;
                    <br />
                    &nbsp;&nbsp;&nbsp;Qualifications
                  </Typography>
                </Grid>
              </Grid>
              <Grid mt="26px"></Grid>
              <Grid
                className="user"
                direction="column"
                display="flex"
                justifyContent="space-between"
                alignItems="center"
                bgcolor="#FFFFFF"
                borderRadius="14px"
                border="1px solid #D9D9D9"
                onClick={handleChallengesClick}
                style={{ cursor: "pointer" }}
              >
                <Grid padding="28px 90px 0px 98px">
                  <Box padding="15px" borderRadius="7px" bgcolor="#84E1CC">
                    <img src={GoalIcon} />
                  </Box>
                </Grid>
                <Grid padding="17px 35px 26px 35px">
                  <Typography fontSize="13px" fontWeight={500} color="#273848">
                    Challenges & Goals
                  </Typography>
                </Grid>
              </Grid>
              <Grid mt="26px"></Grid>
              <Grid
                className="user"
                direction="column"
                display="flex"
                justifyContent="space-between"
                alignItems="center"
                bgcolor="#FFFFFF"
                borderRadius="14px"
                border="1px solid #D9D9D9"
                onClick={handleTeamCultureClick}
                style={{ cursor: "pointer" }}
              >
                <Grid padding="28px 90px 0px 98px">
                  <Box padding="15px" borderRadius="7px" bgcolor="#84E1CC">
                    <img src={TeamsIcon} />
                  </Box>
                </Grid>
                <Grid padding="17px 35px 26px 35px">
                  <Typography fontSize="13px" fontWeight={500} color="#273848">
                    Team & Culture
                  </Typography>
                </Grid>
              </Grid>
              <Grid mt="26px"></Grid>
              <Grid
                className="user"
                direction="column"
                display="flex"
                justifyContent="space-between"
                alignItems="center"
                bgcolor="#FFFFFF"
                borderRadius="14px"
                border="1px solid #D9D9D9"
                style={{ cursor: "pointer" }}
                onClick={handleInterviewprocesClick}
              >
                <Grid padding="28px 90px 0px 98px">
                  <Box padding="15px" borderRadius="7px" bgcolor="#84E1CC">
                    <img src={SymbolsIcon} />
                  </Box>
                </Grid>
                <Grid padding="17px 35px 26px 35px">
                  <Typography fontSize="13px" fontWeight={500} color="#273848">
                    &nbsp;&nbsp;&nbsp;Interview process
                  </Typography>
                </Grid>
              </Grid>
              <Grid mt="26px"></Grid>
            </Grid>
          </>
        )}
        <Grid ref={leftToRight} item xs={8} maxWidth={isMobile ? '100%' : '100%'}>
          {currentPage === 1 && <JobProfileFirst onNext={goToNextPage} setFormData={setFormData} initialData={formData} mode={update}
          />}
          {currentPage === 2 && (
            <JobProfileSecond onBack={goToPreviousPage} onNext={goToNextPage} setFormData={setFormData} initialData={formData} mode={update} />
          )}
          {currentPage === 3 && (
            <JobProfileThird onBack={goToPreviousPage} onNext={goToNextPage} setFormData={setFormData} initialData={formData} mode={update} />
          )}
          {currentPage === 4 && (
            <JobProfilefourth onBack={goToPreviousPage} onNext={goToNextPage} setFormData={setFormData} initialData={formData} mode={update} />
          )}
          {currentPage === 5 && (
            <JobProfilefifth onBack={goToPreviousPage} onNext={goToNextPage} setFormData={setFormData} initialData={formData} mode={update} />
          )}
          {currentPage === 6 && (
            <JobProfileSixth onBack={goToPreviousPage} onNext={goToNextPage} initialData={formData} mode={update} />
          )}
        </Grid>
      </Grid>
    </div>
  );
};

export default JobSpecification;