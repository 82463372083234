import React, { useEffect, useState } from "react";
import Sidebar from "./Sidebar";
import { Container } from "@mui/material";
import { Outlet } from "react-router-dom";
import Loader from "../utils/Loading";

const MainLayout: React.FC = () => {
  const [sidebarExpanded, setSidebarExpanded] = React.useState(false);
  const [loading, setLoading] = useState(true);

  const handleSidebarToggle = (expanded: boolean) => {
    setSidebarExpanded(expanded);
  };

    useEffect(() => {
    const timer = setTimeout(() => {
      setLoading(false);
    }, 2000);

    return () => clearTimeout(timer);
  }, []);

  return (
    <div style={{ display: "flex", width: "100vw", height: "100vh" }}>
      <Sidebar visible={sidebarExpanded} onToggle={handleSidebarToggle} />
      <div
        style={{
          flexGrow: 1,
          transition: "margin-left 1s ease",
          marginLeft: sidebarExpanded ? "200px" : "0",
          overflow: "auto",
          WebkitOverflowScrolling: "touch",
          scrollbarWidth: "none",
          msOverflowStyle: "none",
          ...{ "&::-webkit-scrollbar": { display: "none" } } as any,
        }}  
      >
        { loading ? <Loader/>  :  <Container
          style={{
            // padding: "20px",
            flexGrow: 1,
            boxSizing: "border-box",
            height: "100%",
            // overflow: "auto",
          }}
        >
          <Outlet />
        </Container>
        }
       
      </div>
    </div>
  );
};

export default MainLayout;
