import React from "react";

interface InterviewReminderProps {
  handleNext: () => void;
  handleBack: () => void;
}

const InterviewReminder: React.FC<InterviewReminderProps> = ({ handleNext, handleBack }) => {
  return (
    <>
      <h1>Interview Reminder</h1>
      <button onClick={handleBack}>Back</button>
      <button onClick={handleNext}>Next</button>
    </>
  );
};

export default InterviewReminder;
