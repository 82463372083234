import { Box, Container, Grid, Typography } from "@mui/material";
import Header from "../../layout/Header";
import { getToken } from "../../services/authService";
import { useEffect, useRef } from "react";
import { gsap } from 'gsap';

const drawerWidth = 100;

const Dashboard = () => {
  const user = getToken();
  const boxRef = useRef(null);
  const gridRefs = useRef<(HTMLDivElement | null)[]>([]);

  useEffect(() => {
    const timeLine = gsap.timeline();

    // Animation for the boxRef element
    timeLine.fromTo(
      boxRef.current,
      { y: -100, opacity: 0, scale: 0.9 },
      { y: 0, opacity: 1, scale: 1, duration: 1, ease: "back.out(1.7)" }
    );

    timeLine.fromTo(
      gridRefs.current,
      { y: 100, opacity: 0, scale: 0.9 },
      { y: 0, opacity: 1, scale: 1, duration: 0.6, stagger: 0.3, ease: "back.out(1.7)" }
    );
  },[]);

  return (
    <>
      <Header headerRef={boxRef} pageName="Dashboard" isShow={true} />

      <Box sx={{ width: `calc(100% - ${drawerWidth}px)`, ml: `${drawerWidth}px` }} mt="30px">
        <Box display="flex" flexDirection="column" padding="0px">
          <Grid container spacing={2}>
            {[{ title: "Hiring Manager", count: 36, bgcolor: "#273848", color: "#84E1CC", border: "#D9D9D9" },
              { title: "Jobs", count: 148, bgcolor: "#FFFFFF", color: "#273848", border: "#D6D6D7" },
              { title: "Candidates", count: 462, bgcolor: "#FFFFFF", color: "#273848", border: "#D6D6D7" }].map((item, index) => (
              <Grid item xs={12} sm={6} md={4} key={index} ref={el => gridRefs.current[index] = el}>
                <Box
                  bgcolor={item.bgcolor}
                  borderRadius="27px"
                  padding="20px"
                  border={`1px solid ${item.border}`}
                  className="user-box"
                  sx={{
                    transition: 'transform 0.3s',
                    '&:hover': {
                      transform: 'scale(1.05)',
                      boxShadow: '0 4px 20px rgba(0, 0, 0, 0.2)',
                    }
                  }}
                >
                  <Box
                    padding="10px"
                    bgcolor={`${item.color}1A`}
                    width="fit-content"
                    borderRadius="10px"
                  >
                    <Typography fontSize="20px" lineHeight={1} fontWeight={700} color={item.color}>
                      {item.title}
                    </Typography>
                  </Box>
                  <Typography lineHeight={1} fontSize="75px" fontWeight={700} color={item.color}>
                    {item.count}
                  </Typography>
                </Box>
              </Grid>
            ))}
          </Grid>
        </Box>
      </Box>
    </>
  );
};

export default Dashboard;
